import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'
import {
	getAllUsers,
	revokeApiKey,
	revokeSFToken,
	deleteUser
} from '../utils/db'

const UserEntry = props => {
	const revokeKey = () => {
		console.log('revokeKey')
		try {
			revokeApiKey({ _id: props.user._id }, props.accessToken)
		} catch (e) {
			console.log(e.message)
		} finally {
			window.location.reload()
		}
	}

	const revokeToken = () => {
		console.log('revokeToken')
		try {
			revokeSFToken({ _id: props.user._id }, props.accessToken)
		} catch (e) {
			console.log(e.message)
		} finally {
			window.location.reload()
		}
	}

	const deleteDbUser = () => {
		console.log('deleteUser')
		try {
			deleteUser({ _id: props.user._id }, props.accessToken)
		} catch (e) {
			console.log(e.message)
		} finally {
			window.location.reload()
		}
	}

	return (
		<div className="item ui grid">
			<div className="eight wide column">
				<div className="header">{props.user.username}</div>
				<span>{props.user.email}</span>
			</div>
			<div className="four wide column">
				<div style={{ marginBottom: '.2rem' }}>
					{props.user.apiKey && (
						<span className="ui green label">
							<i className="icon key"></i>
							Api Key
						</span>
					)}
				</div>
				<div>
					{props.user.sfRefreshToken && (
						<span className="ui blue label">
							<i className="icon key"></i>
							SF Token
						</span>
					)}
				</div>
			</div>

			<div className="four wide column">
				<div className="ui compact menu">
					<div className="ui simple dropdown item">
						Options
						<i className="dropdown icon"></i>
						<div className="right menu">
							{props.user.apiKey && (
								<div onClick={revokeKey} className="item">
									Revoke API Key
								</div>
							)}
							{props.user.sfRefreshToken && (
								<div onClick={revokeToken} className="item">
									Revoke SF Token
								</div>
							)}
							<div onClick={deleteDbUser} className="item">
								Delete
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const UserList = () => {
	const { getAccessTokenSilently } = useAuth0()

	const [userList, setUserList] = useState([])
	const [accessToken, setAccessToken] = useState(null)
	const [ready, setReady] = useState(false)

	useEffect(() => {
		const getUserList = async () => {
			try {
				const token = await getAccessTokenSilently({
					audience: `https://api.attorneygroup.com/sf`,
					scope: ['Users', 'Permissions', 'Roles']
				})
				setAccessToken(token)
				const response = await getAllUsers(accessToken)
				setUserList(response.data || [])
			} catch (e) {
				console.log(e.message)
			} finally {
				setReady(true)
			}
		}
		getUserList()
	}, [accessToken, getAccessTokenSilently])

	return (
		ready && (
			<ul className="ui relaxed celled list">
				{userList.map(user => (
					<UserEntry key={user._id} user={user} token={accessToken} />
				))}
			</ul>
		)
	)
}

export default UserList
