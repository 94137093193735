import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { LoginButton } from './components/auth0Components'
import Profile from './components/Profile'
import Nav from './components/Nav'
import UserList from './components/UserList'
import { useAuth0 } from '@auth0/auth0-react'

function App() {
	return (
		<div className="ui container">
			<Router>
				<Switch>
					<Route path="/users">
						<Users />
					</Route>
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</Router>
		</div>
	)
}

function Home() {
	const { isAuthenticated, isLoading } = useAuth0()
	if (!isAuthenticated) {
		return (
			<div className="ui container">
				<LoginButton />
			</div>
		)
	}
	if (isLoading) return <div>Loading...</div>

	return (
		<div>
			<Nav />
			<Profile />
		</div>
	)
}

function Users() {
	const { isLoading } = useAuth0()
	if (isLoading) return <div>Loading...</div>
	return (
		<div>
			<Nav />
			<UserList />
		</div>
	)
}

export default App
