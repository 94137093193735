import { useState, useEffect } from 'react'
import { revokeApiKey, revokeSFToken, generateApiKey } from '../utils/db'

const ApiKey = props => {
	const [user, setUser] = useState(props.user)
	const [showKey, setShowKey] = useState(false)

	useEffect(() => {
		setUser(props.user)
	}, [props])

	const generateKey = async () => {
		try {
			const response = await generateApiKey(user, user.accessToken)
			setUser(response.data)
		} catch (err) {
			console.log(err.message)
		} finally {
			window.location.reload()
		}
	}

	const revokeKey = async () => {
		try {
			const response = await revokeApiKey(user, user.accessToken)
			setUser(response.data)
		} catch (err) {
			console.log(err.message)
		}
	}
	if (user.apiKey) {
		return (
			<div className="ui action input">
				<input
					type="text"
					value={showKey ? user.apiKey : ''}
					className="ui input"
					readOnly
				/>
				<button
					className={`ui ${showKey ? 'red' : 'grey'} icon button`}
					onClick={() => setShowKey(!showKey)}
				>
					<i className={`icon eye${showKey ? ' slash' : ''} black`}></i>
				</button>
				<button className="ui negative button" onClick={revokeKey}>
					Revoke
				</button>
			</div>
		)
	} else
		return (
			<button className="ui primary button" onClick={generateKey}>
				Generate Api Key
			</button>
		)
}

const SFToken = props => {
	const [user, setUser] = useState(props.user)

	useEffect(() => {
		setUser(props.user)
	}, [props])

	const revokeToken = async () => {
		try {
			const response = await revokeSFToken(user, user.accessToken)
			setUser(response.data)
		} catch (err) {
			console.log(err.message)
		}
	}

	if (user.sfRefreshToken) {
		return (
			<div className="ui action input">
				<button className="ui negative button" onClick={revokeToken}>
					Revoke
				</button>
			</div>
		)
	} else {
		const state = encodeURIComponent(JSON.stringify({ user: user._id }))
		const url = `https://login.salesforce.com/services/oauth2/authorize?client_id=${process.env.REACT_APP_SF_CLIENT_ID}&response_type=code&state=${state}&redirect_uri=${process.env.REACT_APP_SF_AUTH_REDIRECT_URI}`

		const sfAuth = () => {
			const win = window.open(
				url,
				'_blank',
				'height=524,width=675,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0'
			)
			const timer = setInterval(() => {
				if (win.closed) {
					clearInterval(timer)
					window.location.reload()
				}
			}, 500)
		}
		return (
			<button className="ui primary button" onClick={sfAuth}>
				Generate Token
			</button>
		)
	}
}

export { ApiKey, SFToken }
