import { useAuth0 } from '@auth0/auth0-react'

const LoginButton = () => {
	const { loginWithRedirect } = useAuth0()

	return (
		<button
			className="ui primary button"
			onClick={() =>
				loginWithRedirect({
					redirect_uri: `${process.env.REACT_APP_SF_REDIRECT_URI}/home`
				})
			}
		>
			Log In
		</button>
	)
}

const LogoutButton = () => {
	const { logout } = useAuth0()

	return (
		<button
			className="ui primary button"
			onClick={() => logout({ returnTo: window.location.origin })}
		>
			Log Out
		</button>
	)
}

export { LoginButton, LogoutButton }
