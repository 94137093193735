import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'
import { getUser, addUser } from '../utils/db'
import UserDetails from './UserDetails'

const Profile = () => {
	const {
		user,
		isAuthenticated,
		getAccessTokenSilently,
		getAccessTokenWithPopup
	} = useAuth0()
	const [userDetails, setUserDetails] = useState(null)
	const [ready, setReady] = useState(false)

	useEffect(() => {
		const getUserInfo = async () => {
			try {
				const accessToken = await getAccessTokenSilently({
					audience: `https://api.attorneygroup.com/sf`,
					scope: 'Users'
				})
				const response = await getUser(user, accessToken)
				response.accessToken = accessToken
				setUserDetails(response)
			} catch (e) {
				console.log(e.message)
				console.log(e)
			} finally {
				setReady(true)
			}
		}
		getUserInfo()
	}, [user, getAccessTokenSilently])

	const newUser = async () => {
		console.log('newUser method')
		try {
			const accessToken = await getAccessTokenWithPopup({
				audience: `https://api.attorneygroup.com/sf`,
				scope: 'Users'
			})
			const response = await addUser(user, accessToken)
			response.accessToken = accessToken
			setUserDetails(response)
			window.location.reload()
		} catch (e) {
			console.log(e.message)
		}
	}

	return (
		isAuthenticated &&
		ready && (
			<div className="ui container">
				<div className="ui grid">
					<div className="profile-info eight wide column">
						<img src={user.picture} alt={user.name} />
						<h2>{user.name}</h2>
						<p>{user.email}</p>
					</div>

					<div className="api-info eight wide column">
						{userDetails && <UserDetails user={userDetails} />}
						{!userDetails && (
							<button className="ui prmary button" onClick={newUser}>
								Add User
							</button>
						)}
					</div>
				</div>
			</div>
		)
	)
}

export default Profile
