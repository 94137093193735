import { ApiKey, SFToken } from './tokenButtons'

export default function UserDetails(props) {
	const { user } = props
	return (
		<>
			<h3>User Details</h3>
			<h4>API Key</h4>
			<ApiKey user={user} />
			<h4>SF Authentication</h4>
			<SFToken user={user} />
		</>
	)
}
