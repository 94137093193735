import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'

ReactDOM.render(
	<Auth0Provider
		domain="attorneygroup.us.auth0.com"
		clientId="DAisPhlmpyu899n278mIaMku9dbpRiqM"
		redirectUri={window.location.origin}
		audience="https://attorneygroup.us.auth0.com/api/v2/"
		scope="read:current_user update:current_user_metadata"
	>
		<App />
	</Auth0Provider>,
	document.getElementById('root')
)
