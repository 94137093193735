import axios from 'axios'
import crypto from 'crypto'

const getUser = async (user, authToken) => {
	const { email } = user
	const options = {
		params: { email },
		headers: {
			authorization: `Bearer ${authToken}`
		},
		method: 'get',
		url: `${process.env.REACT_APP_DB_ENDPOINT}/users`
	}
	try {
		const response = await axios.request(options)
		return response.data
	} catch (e) {
		console.log('GetUser Error:', e.message)
		return {}
	}
}

const getAllUsers = async authToken => {
	if (!authToken) return {}
	const options = {
		headers: {
			authorization: `Bearer ${authToken}`
		},
		method: 'get',
		url: `${process.env.REACT_APP_DB_ENDPOINT}/users`
	}

	try {
		const response = await axios.request(options)
		return response.data
	} catch (e) {
		console.log('GetUser Error:', e.message)
		return {}
	}
}

const addUser = async (user, authToken) => {
	console.log(user)
	const { email, name } = user
	const options = {
		data: { email, username: name },
		headers: {
			authorization: `Bearer ${authToken}`
		},
		method: 'post',
		url: `${process.env.REACT_APP_DB_ENDPOINT}/users`
	}
	try {
		console.log('Add User options:', options)
		const response = await axios.request(options)
		return response.data
	} catch (e) {
		console.log('Add User error:', e.message)
		return {}
	}
}

const updateUser = async (user, body, authToken) => {
	const options = {
		url: `${process.env.REACT_APP_DB_ENDPOINT}/users/${user._id}`,
		headers: {
			authorization: `Bearer ${authToken}`
		},
		method: 'patch',
		data: body
	}
	try {
		const response = await axios.request(options)
		return response.data
	} catch (err) {
		console.log(err.message)
		return {}
	}
}

const deleteUser = async (user, authToken) => {
	const options = {
		url: `${process.env.REACT_APP_DB_ENDPOINT}/users/${user._id}`,
		headers: {
			authorization: `Bearer ${authToken}`
		},
		method: 'delete'
	}
	try {
		const response = await axios.request(options)
		return response.data
	} catch (err) {
		console.log(err.message)
		return {}
	}
}

const revokeApiKey = (user, accessToken) => {
	return updateUser(user, { apiKey: null }, accessToken)
}
const revokeSFToken = (user, accessToken) => {
	return updateUser(user, { sfRefreshToken: null }, accessToken)
}
const generateSFToken = async (user, accessToken) => {
	const codeOptions = {
		params: {
			client_id: process.env.REACT_APP_SF_CLIENT_ID,
			redirect_uri: process.env.REACT_APP_SF_REDIRECT_URI,
			response_type: 'code'
		},
		method: 'get',
		url: process.env.REACT_APP_SF_AUTH_URL
	}
	const codeResponse = await axios.request(codeOptions)
	console.log(codeResponse)

	const key = crypto.randomBytes(32).toString('hex')
	return updateUser(user, { sfRefreshToken: key }, accessToken)
}
const generateApiKey = (user, accessToken) => {
	const key = crypto.randomBytes(32).toString('hex')
	console.log(key)
	return updateUser(user, { apiKey: key }, accessToken)
}

export {
	getAllUsers,
	getUser,
	addUser,
	updateUser,
	deleteUser,
	revokeApiKey,
	revokeSFToken,
	generateApiKey,
	generateSFToken
}
