import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import { LogoutButton } from './auth0Components'

const Nav = () => {
	const { user } = useAuth0()

	const usersLink = user['https://api.attorneygroup.com/sf-roles'].includes(
		'Admin'
	) ? (
		<Link className="item" to="/users">
			Users
		</Link>
	) : null

	return (
		<div className="ui secondary pointing menu">
			<Link className="item" to="/">
				Profile
			</Link>
			{usersLink}
			<div className="right menu">
				<img
					className="ui middle aligned mini image"
					src={user.picture}
					alt={user.name}
				/>
				<LogoutButton />
			</div>
		</div>
	)
}

export default Nav
